//light
@font-face {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 300;
    font-display: block;
    src: url('./assets/fonts/font-awesome/fa-light-300.eot');
    src:
        url('./assets/fonts/font-awesome/fa-light-300.eot?#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/font-awesome/fa-light-300.woff2') format('woff2'),
        url('./assets/fonts/font-awesome/fa-light-300.woff') format('woff'),
        url('./assets/fonts/font-awesome/fa-light-300.ttf') format('truetype'),
        url('./assets/fonts/font-awesome/fa-light-300.svg#fontawesome')
            format('svg');
}

.fal {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
}

//font awesome reference
//regular
@font-face {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('./assets/fonts/font-awesome/fa-regular-400.eot');
    src:
        url('./assets/fonts/font-awesome/fa-regular-400.eot?#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/font-awesome/fa-regular-400.woff2') format('woff2'),
        url('./assets/fonts/font-awesome/fa-regular-400.woff') format('woff'),
        url('./assets/fonts/font-awesome/fa-regular-400.ttf') format('truetype'),
        url('./assets/fonts/font-awesome/fa-regular-400.svg#fontawesome')
            format('svg');
}

.far,
.fa {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
}

//solid
@font-face {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url('./assets/fonts/font-awesome/fa-solid-900.eot');
    src:
        url('./assets/fonts/font-awesome/fa-solid-900.eot?#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/font-awesome/fa-solid-900.woff2') format('woff2'),
        url('./assets/fonts/font-awesome/fa-solid-900.woff') format('woff'),
        url('./assets/fonts/font-awesome/fa-solid-900.ttf') format('truetype'),
        url('./assets/fonts/font-awesome/fa-solid-900.svg#fontawesome')
            format('svg');
}

.fas {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
}

//end font awesome reference

@import './styles/variables';
@import 'bootstrap';
@import '/node_modules/@swimlane/ngx-datatable/index.css';
@import '/node_modules/@swimlane/ngx-datatable/themes/bootstrap.css';
@import '/node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import 'ngx-toastr/toastr-bs5-alert';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';
@import './styles/typographie';
@import './styles/ag-grid';
@import './styles/mixins';
@import './styles/print';
@import './styles/colorpicker';
@import './styles/head-content';
@import './styles/pdf-uploader';
@import './styles/layout';
@import './styles/bootstrap-customize';
@import './styles/form-elements';
@import './styles/material/styles';
@import './styles/tables';
@import './styles/preloader';
@import './styles/card';
@import './styles/pages/dashboard';
@import './styles/pages/404';
@import './styles/font-awesome';
@import './styles/focus-ring';
@import './styles/ng-select';
@import './styles/icomoon';
@import './styles/os-overwrites';
@import './styles/utilities';
